<template>
  <div>
    <div
      v-if="isShowEventsList"
      class="tw-h-full tw-absolute lg:tw-left-[80px] tw-w-full lg:tw-w-[calc(100dvw-580px)] tw-bg-white tw-z-[100000] tw-px-6 tw-py-7 tw-overflow-y-auto tw-h-screen"
    >
      <div class="tw-flex tw-items-center tw-justify-between">
        <zem-card-title class="tw-mb-0">История заказа</zem-card-title>
        <div @click="isShowEventsList = false">
          <zem-icon class="cursor-pointer" name="close" size="18" />
        </div>
      </div>
      <div v-for="note in $refs.order.element['last_notes'].data" :key="note.id" class="tw-mt-6">
        <p class="tw-text-xs tw-text-[#3E3E3E]">
          <span class="tw-text-[#9BA6B2]">{{ dateFormat(note.created_at) }}</span> {{ note.type.title }}:
          {{ note.params.message }}
        </p>
      </div>
    </div>

    <SidebarLeft :class="{'sidebar-left-show': sidebars.leftSidebar}">
      <FunnelsOrdersForm v-if="isLeftSidebarVisible && !filter" @on-change-show-settings="onSettings" />
      <FilterOrdersForm v-if="isLeftSidebarVisible && filter" @on-update-table="updateData" />
    </SidebarLeft>

    <TableContainer
      ref="table-container"
      :columns="tableData"
      :counter-filter="searchCount"
      title-add-button="Заказ"
      :is-archive="$can('edit', 'order-read-archive')"
      :is-archiving="$can('edit', 'order-trash')"
      :is-filter="false"
      :is-loading-items="$store.state.other.isLoadingItems"
      :is-loading-new-item="$store.state.other.isLoadingNewItem"
      :is-remove-from-archive="$can('edit', 'order-delete')"
      :rows="$store.state.orders.orders"
      :show-add-button="false"
      :sorting-fields="sortingFields"
      :title="$store.state.orders.section.title || 'Заказы'"
      name="orders"
      @on-selected="selectItem"
      @delete-item="deleteItem"
      @archive-items="archiveItems"
      @update-data="updateData"
      @return-from-archive="returnFromArchive"
      @next-page="loadNewData"
      @on-change-archive="
        () => {
          $store.commit('orders/changePage', 1)
          if ($route.name !== 'orders') $router.push({name: 'orders'})
        }
      "
    >
      <template v-slot:head-buttons-after>
        <button
          class="tw-flex tw-items-center tw-text-xs text-primary tw-font-semibold hover:tw-underline tw-whitespace-nowrap"
          @click="
            filter = false
            $store.commit('sidebars/changeLeftSidebar', true)
          "
        >
          <i-funnel class="tw-mr-1 tw-w-3 tw-h-3 tw-fill-[#0db2b2]" />
          <span class="mobile-d-none">Воронки заказов</span>
        </button>
        <button
          class="tw-flex tw-items-center tw-text-xs text-primary tw-font-semibold hover:tw-underline tw-whitespace-nowrap"
          @click="
            filter = true
            $store.commit('sidebars/changeLeftSidebar', true)
          "
        >
          <FilterIcon class="tw-mr-1 tw-font-bold" size="14" />
          <span class="mobile-d-none">Фильтр</span>
        </button>
        <ZemButton icon @click="onCreateItem">
          <img :src="require('@/assets/icons/plus.svg')" alt="" class="tw-mb-[1px]" />
          <span class="mobile-d-none">Заказ</span>
        </ZemButton>
      </template>
    </TableContainer>

    <SidebarRight :class="{'sidebar-right--show': sidebars.rightSidebar && multiSelectedList.length < 2}">
      <OrderCreate
        v-show="isAccessOrder && $route.name !== 'orders-settings-id'"
        ref="order-create"
        :loading-new-element="isLoadingCreatedOrder"
        :default-role="defaultRole"
        @on-create="onCreateOrUpdate"
        @on-update="onCreateOrUpdate"
        @new-state="isNew = $event"
        @on-close="
          isNew = false
          isEdit = false
          isSettings = false
        "
      />
      <OrderDetails
        v-show="!isAccessOrder && $route.name !== 'orders-settings-id'"
        ref="order"
        @on-update="onCreateOrUpdate"
        @on-edit="onEdit"
        @on-close="isShowEventsList = false"
        @on-show-events-list="isShowEventsList = true"
      />
      <OrderSettings
        v-if="$route.name === 'orders-settings-id'"
        ref="order-settings"
        @on-close="isSettings = false"
        @on-save="onUpdateSettings"
      />
    </SidebarRight>

    <SidebarRight :class="{'sidebar-right--show': multiSelectedList.length > 1}">
      <OrderMultipleChanged :orders="multiSelectedList" />
    </SidebarRight>
  </div>
</template>

<script>
import SidebarRight from '../components/SidebarRight'
import TableContainer from '@/components/TableContainer'
import {eventBus} from '@/main'
import {errorHandler, logger} from '@/assets/scripts/scripts'
import SidebarLeft from '@/components/SidebarLeft.vue'
import ZemLink from '@/components/ui/ZemLink.vue'
import {FilterIcon, PlusIcon} from 'vue-feather-icons'
import ZemButton from '@/components/ui/ZemButton.vue'
import ZemIcon from '@/components/ui/ZemIcon.vue'
import OrderDetails from '@/components/Orders/OrderDetails.vue'
import FunnelsOrdersForm from '@/components/Orders/FunnelsOrdersForm.vue'
import FilterOrdersForm from '@/components/Orders/FilterOrdersForm.vue'
import OrdersService from '@/services/orders.service'
import OrderCreate from '@/components/Orders/OrderCreate.vue'
import OrderSettings from '@/components/Orders/OrderSettings.vue'
import ZemCardTitle from '@/components/ui/ZemCardTitle.vue'
import dayjs from 'dayjs'
import IFunnel from '@/components/icons/iFunnel.vue'
import OtherService from '@/services/other.service'
import OrderMultipleChanged from '@/components/Orders/OrderMultipleChanged.vue'

export default {
  components: {
    OrderMultipleChanged,
    IFunnel,
    ZemCardTitle,
    OrderSettings,
    OrderCreate,
    FunnelsOrdersForm,
    OrderDetails,
    FilterOrdersForm,
    ZemIcon,
    ZemButton,
    ZemLink,
    SidebarLeft,
    SidebarRight,
    TableContainer,
    FilterIcon,
    PlusIcon,
  },
  data() {
    return {
      isLeftSidebarVisible: true,
      sortingFields: [
        'id',
        'title',
        'responsible.name',
        'order_pipelines.name',
        'order_pipeline_statuses.name',
        'budget',
        'objects.address',
      ],
      tableData: [],
      filter: false,
      isNew: false,
      isEdit: false,
      isSettings: false,
      isShowEventsList: false,
      isLoadingCreatedOrder: false,
      currentItem: {
        title: '',
        budget: 0,
        responsibleBy: {
          title: '',
          value: '',
        },
        status: {
          title: '',
          value: '',
        },
        object: {
          title: '',
          value: '',
        },
      },
      defaultRole: null,
    }
  },

  computed: {
    searchCount() {
      return this.$store.state.orders.searchCount
    },
    sidebars() {
      return this.$store.state.sidebars
    },
    isAccessOrder() {
      return this.$can('edit', 'order-update')
    },
    multiSelectedList() {
      return this.$store.state.orders.selectedItemsForDelete
    },
  },

  created() {
    this.tableData = [
      {
        label: 'ID',
        field: 'real_id',
        sortField: 'id',
        showInTable: true,
        sort: 0,
      },
      {
        label: 'Название',
        field: 'title',
        sortField: 'title',
        showInTable: true,
        sort: 0,
      },
      {
        label: 'Ответственный',
        field: 'responsible_by.data.name',
        sortField: 'responsible.name',
        showInTable: true,
        sort: 0,
      },
      {
        label: 'Воронка',
        field: 'pipeline.data.name',
        sortField: 'order_pipelines.name',
        showInTable: true,
        sort: 0,
      },
      {
        label: 'Этап',
        field: 'status.data.name',
        sortField: 'order_pipeline_statuses.name',
        showInTable: true,
        sort: 0,
      },
      {
        label: 'Бюджет, руб.',
        field: 'budget.currency.no_style',
        sortField: 'budget',
        showInTable: true,
        sort: 0,
      },
      {
        label: 'Объект',
        field: 'object.data.address',
        sortField: 'objects.address',
        sort: 0,
        showInTable: true,
      },
    ]
  },

  async mounted() {
    const {id} = this.$route.params

    if (this.$route.name === 'orders-settings-id') this.isSettings = true

    const width = window.innerWidth
    this.$store.commit('sidebars/changeLeftSidebar', width > 768)

    this.$store.commit('other/onChangeLoadingItems', true)
    this.$store.commit('sidebars/changeRightSidebar', id !== undefined)
    eventBus.$on('update-settings', this.updateData)

    await OrdersService.getTree()
    await this.updateData()

    if (id && this.$route.name !== 'orders-settings-id') {
      this.$store.commit('orders/itemsForDelete', [id])
      if (this.isAccessOrder) await this.$refs['order-create'].getOrder()
      else await this.$refs.order.getOrder()
    } else {
      this.$store.commit('orders/itemsForDelete', [])
    }

    OtherService.getSettingsOrder().then(r => {
      this.defaultRole = r.data.data[0].value.client_human_position_id
    })
  },

  methods: {
    dateFormat(date) {
      return dayjs.unix(date).format('DD.MM.YYYY в HH:mm:ss')
    },
    async onSettings(e) {
      this.isSettings = e
      await this.$refs['order-settings'].getStage()
    },
    async onCreateOrUpdate(element) {
      this.isLoadingCreatedOrder = true
      const orderData = this.$refs['order-create'].$data
      const customers = orderData.customers
      const performers = orderData.performers
      const {responsibleBy, title, status, object, params, budgetEdit} = orderData.element
      const data = {
        title,
        budget: budgetEdit,
        params,
      }
      if (responsibleBy.value) data['responsible_by'] = responsibleBy.value
      if (status.value) data['status_id'] = status.value
      if (object.value) data['object_id'] = object.value

      if (customers.length || performers.length) data['participants'] = []

      if (customers.length)
        customers.forEach(person => {
          data['participants'].push({
            community_status_id: person.community_status.value,
            ...(person.human_position.value ? {human_position_id: person.human_position.value} : {}),
            human_id: person.human.value,
          })
        })

      if (performers.length)
        performers.forEach(person => {
          data['participants'].push({
            community_status_id: person.community_status.value,
            ...(person.human_position.value ? {human_position_id: person.human_position.value} : {}),
            human_id: person.human.value,
          })
        })

      if (!element)
        OrdersService.createOrder(data)
          .then(() => {
            this.$store.commit('sidebars/changeRightSidebar', false)
            this.isNew = false
            this.updateData()
            this.isLoadingCreatedOrder = false
            OrdersService.getTree()
          })
          .catch(e => {
            this.isLoadingCreatedOrder = false
            errorHandler(e)
          })
      else
        OrdersService.updateOrder(element.id, data)
          .then(() => {
            this.$refs.order.getOrder()
            this.isEdit = false
            this.updateData()
            this.isLoadingCreatedOrder = false
            OrdersService.getTree()
          })
          .catch(e => {
            this.isLoadingCreatedOrder = false
            errorHandler(e)
          })
    },
    async onEdit() {
      const order = await Object.assign({}, this.$refs.order.element)
      this.currentItem = {
        ...order,
        budget: order.budgetEdit,
      }

      const handlerHuman = data => {
        return {
          community_status: {
            title: data.community_status.data.title,
            value: data.community_status.data.id,
          },
          human_position: {
            title: data.human_position.data.title,
            value: data.human_position.data.id,
          },
          human: {
            title: data.human.data.fio,
            value: data.human.data.id,
          },
          isLoading: false,
        }
      }

      // this.$refs.order.customers = await order.participants.data
      //   .filter(el => el.community_status.data.title === 'Заказчик')
      //   .map(el => handlerHuman(el))
      //
      // this.$refs.order.performers = await order.participants.data
      //   .filter(el => el.community_status.data.title === 'Исполнитель')
      //   .map(el => handlerHuman(el))
    },
    onCreateItem() {
      this.isNew = true
      this.$store.commit('sidebars/changeRightSidebar', true)
      this.$store.commit('orders/itemsForDelete', [])
      if (this.$route.name !== 'orders') this.$router.push({name: 'orders'})
      this.$refs['order-create'].$data.performers = []
      this.$refs['order-create'].$data.customers = []
      this.$refs['order-create'].$data.newElement = true
      const defaultValue = {
        title: null,
        value: null,
      }
      this.$refs['order-create'].$data.element = {
        responsibleBy: defaultValue,
        status: defaultValue,
        object: defaultValue,
        objectValue: null,
        budgetEdit: 0,
      }
    },
    async onUpdateSettings() {
      const element = this.$refs['order-settings'].element
      this.$refs['order-settings'].editItemLoading = true
      const {type} = await this.$route.query
      const {name, ordering, amocrm_pipeline_id, params_schema} = element
      if (type === 'pipeline') {
        const body = {
          name,
          ordering,
          amocrm_pipeline_id,
        }
        if (params_schema.length) {
          body['params'] = {}
          params_schema.forEach(el => {
            body.params[el.key] = el.value
          })
        }
        OrdersService.updateFunnel(element.id, body)
          .then(async () => {
            this.$refs['order-settings'].editElement = false
            this.$refs['order-settings'].changeSettings = false
            this.$refs['order-settings'].editItemLoading = false
            await OrdersService.getTree()
            await this.updateData()
          })
          .catch(e => {
            errorHandler(e)
            this.$refs['order-settings'].editItemLoading = false
          })
      } else {
        OrdersService.updateStage(element.id, {
          name: element.name,
          ordering: element.ordering,
          params: element.params,
          amocrm_status_id: element.amocrm_status_id,
          show_in_graph: element.show_in_graph,
        })
          .then(async () => {
            this.$refs['order-settings'].editElement = false
            this.$refs['order-settings'].changeSettings = false
            this.$refs['order-settings'].editItemLoading = false
            await OrdersService.getTree()
            await this.updateData()
          })
          .catch(e => {
            errorHandler(e)
            this.$refs['order-settings'].editItemLoading = false
          })
      }
    },
    updateData() {
      OrdersService.getAllOrders()
    },
    loadNewData() {
      const page = this.$store.state.orders.paginationPage
      if (this.$store.state.orders.orders.length) {
        this.$store.commit('orders/changePage', page + 1)
        OrdersService.getAllOrders(true)
      }
    },
    async selectItem(data) {
      if (data.column.field !== 'checkbox') {
        this.isNew = false
        this.isEdit = false
        this.isSettings = false
        this.$store.commit('sidebars/changeRightSidebar', !this.$store.state.orders.isArchive)
        const {id} = this.$route.params
        if (id !== data.row.id) {
          await this.$router.push({name: 'orders-id', params: {id: data.row.id}})
          if (this.isAccessOrder) await this.$refs['order-create'].getOrder()
          else await this.$refs.order.getOrder()
          await this.onEdit()
        }
      }
    },
    async returnFromArchive() {
      await OrdersService.archiveItems('orders')
      await this.updateData()
      await OrdersService.getTree()
    },
    archiveItems() {
      this.$store.commit('sidebars/changeRightSidebar', false)
      OrdersService.deleteItems().then(r => {
        this.$toast.success(r.data['message'])
        this.$router.push({name: 'orders'})
        this.updateData()
        OrdersService.getTree()
      })
    },
    deleteItem() {
      this.$refs['table-container'].loadingDelete = true
      OrdersService.deleteItems(true)
        .then(r => {
          this.$toast.success(r.data.message)
          this.$refs['table-container'].loadingDelete = false
          this.$refs['table-container'].showDeleteModal = false
          this.$store.commit('sidebars/changeRightSidebar', false)
          this.$store.commit('orders/itemsForDelete', [])
          eventBus.$emit('new-state', false)
          this.updateData()
          OrdersService.getTree()
        })
        .catch(e => {
          errorHandler(e)
        })
    },
  },
  beforeDestroy() {
    this.$store.commit('orders/updateElements', {data: []})
    this.$store.commit('orders/changePagination', {
      meta: {
        custom: {
          total: null,
        },
        pagination: {
          current_page: null,
          total_pages: null,
          total: null,
        },
      },
    })
    eventBus.$off('update-settings')
    eventBus.$off('update-settings-orders')
  },
}
</script>
