<template>
  <div class="tw-h-full tw-p-5">
    <div class="d-flex justify-content-between" style="align-items: start">
      <div>
        <zem-title>Множественное изменение</zem-title>
        <p class="mb-3 mt-1 text-muted text-normal">{{ declOfNum(orders.length, words) }}</p>
      </div>
      <div class="d-flex align-center">
        <zem-link v-if="!isSaving" class="mr-2 mobile-d-none" @click="cancelOrders">Отменить</zem-link>
        <zem-button :disabled="isSaving" class="mr-2 mobile-d-none" @click="saveOrders">
          <save-icon v-if="!isSaving" size="16"></save-icon>
          <zem-preloader v-else :size="16" class="mr-0" color="light" />
          <span>Сохранить</span>
        </zem-button>
      </div>
    </div>
    <div class="zem-collapse-table rows">
      <div class="zem-collapse-table__row">
        <div class="zem-collapse-table__column title">Статус</div>
        <ZemDropdownList v-model="status" :options="orderStatuses" class="mt-0" type="object" />
      </div>
    </div>
  </div>
</template>

<script>
import ZemTitle from '@/components/ui/Title.vue'
import ZemDropdownList from '@/components/ui/ZemDropdownList.vue'
import ZemInput from '@/components/ui/ZemInput.vue'
import ZemMultiSelect from '@/components/ui/ZemMultiSelect.vue'
import ZemRadioButton from '@/components/ui/ZemRadioButton.vue'
import zemCollapse from '@/components/ui/ZemCollapse.vue'
import ZemLink from '@/components/ui/ZemLink.vue'
import {SaveIcon} from 'vue-feather-icons'
import {errorHandler} from '@/assets/scripts/scripts'
import OrdersService from '@/services/orders.service'
import ZemPreloader from '@/components/ui/ZemPreloader.vue'
import ZemButton from '@/components/ui/ZemButton.vue'

export default {
  components: {
    ZemButton,
    ZemPreloader,
    ZemLink,
    ZemRadioButton,
    ZemMultiSelect,
    ZemInput,
    ZemDropdownList,
    ZemTitle,
    zemCollapse,
    SaveIcon,
  },
  props: {
    orders: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      words: ['заказ', 'заказа', 'заказов'],
      isSaving: false,
      status: {
        title: null,
        value: null,
      },
      orderStatuses: [],
    }
  },
  mounted() {
    OrdersService.getOrderStatuses().then(r => {
      this.orderStatuses = r.data.data.map(status => ({
        title: `${status.pipeline ? status.pipeline.data.name + ' - ' : ''}${status.name}`,
        value: status.id,
      }))
    })
  },
  methods: {
    cancelOrders() {
      this.$store.commit('orders/itemsForDelete', [])
      this.$store.commit('sidebars/changeRightSidebar', false)
    },
    saveOrders() {
      this.isSaving = true

      OrdersService.updateMultipleOrdersStatuses(this.status.value, {ids: this.orders})
        .then(r => {
          this.$toast.success(r.data.message)
          this.isSaving = false
          OrdersService.getAllOrders()
          this.$store.commit('sidebars/changeRightSidebar', false)
          if (this.$route.name !== 'orders') this.$router.push({name: 'orders'})
        })
        .catch(e => {
          this.isSaving = false
          errorHandler(e)
        })
    },
    declOfNum(n, text_forms) {
      n = Math.abs(n) % 100
      const n1 = n % 10
      if (n > 10 && n < 20) {
        return `${n} ${text_forms[2]}`
      }
      if (n1 > 1 && n1 < 5) {
        return `${n} ${text_forms[1]}`
      }
      if (n1 === 1) {
        return `${n} ${text_forms[0]}`
      }
      return `${n} ${text_forms[2]}`
    },
  },
}
</script>

<style lang="scss" scoped></style>
